<script setup lang="ts">
import { useGlobals, useBrands } from '@/composables';
import { capitalize } from '@/utils/helpers';

const { t } = useGlobals();
const { brand } = useBrands();

const emits = defineEmits<{
  close: [];
}>();
</script>

<template>
  <h2 v-trans class="h-mb-16">How to transfer a .uk domain?</h2>
  <Trans
    tag="p"
    class="h-mb-16"
    :translate-params="{
      brand: capitalize(brand),
    }"
  >
    After completing the setup form you will receive an email containing your
    <strong>IPS tag</strong>
    . Update the given IPS tag at your current provider to complete the transfer
    of your domain.
  </Trans>
  <div class="d-flex justify-content-end">
    <HButton variant="outline" @click="emits('close')">
      {{ t('Close') }}
    </HButton>
  </div>
</template>
