<script setup lang="ts">
import type { ModalEmits } from '@/types';
import { getServiceProviderForBrArticle } from '@/utils/services/supportArticleService';

interface Emits extends ModalEmits {}

const articleUrl = getServiceProviderForBrArticle();

const emits = defineEmits<Emits>();
</script>

<template>
  <Trans tag="p" class="h-mb-16">
    1. Access your account on
    <a
      href="https://registro.br/login"
      target="_blank"
      class="font-weight-bold"
    >
      Registro.br
    </a>
    website
  </Trans>
  <p class="h-mb-16">
    <span v-trans>
      2. Change your domain service provider and ownership to HSTDOMAINS (127).
      We have the step-by-step on how to perform this process.
    </span>
    <span>&nbsp;</span>
    <a :href="articleUrl" target="_blank">
      <Trans>Learn more</Trans>
      <span>&#8228;</span>
    </a>
  </p>
  <p v-trans class="h-mb-24">
    3. Return to this screen, fill in the transfer data using your domain's
    registration information, and finalize the transfer.
  </p>
  <div class="d-flex justify-content-end">
    <HButtonV1
      no-margin
      primary
      outline
      class="float-right"
      @click="emits('close')"
    >
      Close
    </HButtonV1>
  </div>
</template>
