import type { ModalObject } from '@/types';

export default [
  {
    name: 'FreeDomainModal',
    type: 'HModal',
    steps: [
      {
        hideX: true,
      },
      {
        goBackText: 'Search for domain',
        hideX: true,
      },
    ],
  },
  {
    name: 'WhoIsDetailsSummaryModal',
    type: 'HModal',
    subtype: 'HDomains',
  },
  {
    name: 'RegistroBrErrorModal',
    type: 'HModal',
    subtype: 'HDomains',
    steps: [
      {
        modalTitleIcon: {
          name: 'icon-error',
          color: 'danger',
        },
        title:
          'Your CPF/CNPJ is set up for another service provider at your Registro.BR account',
        hideX: true,
      },
    ],
  },
  {
    name: 'RegistroBrTransferTermsModal',
    type: 'HModal',
    subtype: 'HDomains',
    steps: [
      {
        title: 'How to start a .br domain transfer?',
        hideX: true,
      },
    ],
  },
  {
    name: 'SelectBulkActionModal',
    type: 'HModal',
    subtype: 'HDomains',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'ConfirmBulkActionModal',
    type: 'HModal',
    subtype: 'HDomains',
  },
  {
    name: 'BulkActionDomainsList',
    type: 'HModal',
    subtype: 'HDomains',
  },
  {
    name: 'BulkActionProgressModal',
    type: 'HModal',
    subtype: 'HDomains',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'DomainNotValidNameserverModal',
    type: 'HModal',
    subtype: 'HDomains',
    steps: [
      {
        title: 'Confirm nameservers',
        subtitle:
          'The nameservers you’ve entered are not responding to the domain. There might be a mistake in the nameservers, or their DNS zone might not have been configured correctly.',
      },
    ],
  },
  {
    name: 'DomainForwardingBulkActionModal',
    type: 'HModal',
    subtype: 'HDomains',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'SelectDomainModal',
    type: 'HModal',
    subtype: 'HDomains',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'DomainAutoRenewBulkActionModal',
    type: 'HModal',
    subtype: 'HDomains',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'LtDomainTransferInstructionsModal',
    type: 'HModal',
    subtype: 'HDomains',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'UkDomainTransferTermsModal',
    type: 'HModal',
    subtype: 'HDomains',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'TransferLockedErrorModal',
    type: 'HModal',
    subtype: 'HDomains',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'ChangeSldModal',
    type: 'HModal',
    subtype: 'HDomains',
    props: {
      hideX: true,
    },
  },
  {
    name: 'SuspiciousDomainModal',
    type: 'HModal',
    subtype: 'HDomains',
    props: {
      hideX: true,
    },
  },
  {
    name: 'DnsRecordsListModal',
    type: 'HModal',
    subtype: 'HDomains',
    closingType: 'input',
    steps: [
      {
        hideX: true,
        width: 700,
      },
    ],
  },
  {
    name: 'DnsRecordsDeleteModal',
    type: 'HModal',
    subtype: 'HDomains',
    closingType: 'input',
    props: {
      hideX: true,
    },
  },
  {
    name: 'DnsRecordsAddModal',
    type: 'HModal',
    subtype: 'HDomains',
    closingType: 'input',
    props: {
      hideX: true,
    },
  },
  {
    name: 'DomainQrCodeModal',
    type: 'HModal',
    subtype: 'HDomains',
    props: {
      hideX: true,
    },
  },
] as ModalObject[];
