<script setup lang="ts">
import { useGlobals, useBrands } from '@/composables';
import { capitalize } from '@/utils/helpers';
import { getHowToTransferLtDomainArticle } from '@/utils/services/supportArticleService';

const { t } = useGlobals();
const { brand } = useBrands();

const emits = defineEmits<{
  close: [];
}>();
</script>

<template>
  <h2 v-trans class="h-mb-16">How to transfer a .lt domain?</h2>
  <h4 v-trans class="h-mb-8">1. Get your transfer authorization code</h4>
  <p v-trans class="h-mb-16">
    You can obtain your authorization code from your current domain registrar.
  </p>
  <h4 v-trans class="h-mb-8">2. Enter the correct information</h4>
  <p v-trans class="h-mb-16">
    In order to transfer the domain successfully, you would need to enter the
    exact details you currently use as your domain contact.
  </p>
  <p />
  <Trans
    tag="p"
    :translate-params="{
      url: getHowToTransferLtDomainArticle(),
      brand: capitalize(brand),
    }"
  >
    It is suggested to copy the details and paste inside of {brand} domain
    transfer form.
    <a href="{url}" target="_blank" class="transfer-article-url">Learn more</a>
    on how to find your current domain contact details.
  </Trans>
  <HSnackbar variant="warning" class="h-mt-16 h-mb-24">
    <Trans>Contact details must match for the transfer to be completed</Trans>
  </HSnackbar>
  <div class="d-flex justify-content-end">
    <HButton v-qa-generate variant="outline" @click="emits('close')">
      {{ t('Close') }}
    </HButton>
  </div>
</template>

<style lang="scss">
.transfer-article-url {
  font-weight: 700;
}
</style>
