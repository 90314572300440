import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useResource } from './useResource';

import store from '@/store';
import {
  useResourcesStore,
  useSubscriptionsStore,
  useProfileStore,
} from '@/stores';
import type {
  HResourceState,
  HResourceType,
  MonolithResourceType,
  ProWebsiteSubscriptionType,
  RequestConfig,
} from '@/types';
import {
  HRESOURCES_STATE,
  HRESOURCES_TYPE,
  HRESOURCE_ITEM_STATE,
} from '@/types';
import {
  filterPendingResources,
  mapResourcesToSubscriptions,
  filterUpgradableHostingResources,
  filterNonWebsiteBuilderHostingResources,
} from '@/utils/mappers/resourcesMapper';
import {
  filterCanceledHomepageResources,
  mapResourceToHomepageOrder,
  groupResourcesToSections,
  filterActivatingGoogleWorkspaces,
  filterActiveResource,
  type HomepageOrder,
  filterActiveResourceItems,
} from '@/utils/mappers/resourcesToHomepageMapper';
import { mapResourcesToMonolithOrder } from '@/utils/mappers/resourcesToOrdersMapper';

export const useResourceMapper = () => {
  const subscriptionStore = useSubscriptionsStore();
  const resourcesStore = storeToRefs(useResourcesStore());
  const { fetchResources } = useResourcesStore();
  const profileStore = useProfileStore();

  const { canRenewResource } = useResource();

  const resourcesWithSubscriptions = computed(() =>
    mapResourcesToSubscriptions({
      resources: resourcesStore.resources.value,
      subscriptions: subscriptionStore.subscriptions,
    }),
  );

  const isSubscriptionsAndResourcesLoaded = computed(
    () => subscriptionStore.isLoaded && resourcesStore.isLoaded,
  );

  const activeResources = computed(() =>
    resourcesWithSubscriptions.value.filter(filterActiveResource),
  );

  const activeHostingResources = computed(() =>
    getResourcesByTypesAndStates({
      types: [HRESOURCES_TYPE.HOSTING],
      states: [HRESOURCES_STATE.ACTIVE],
    }),
  );

  const activeHostingResourceItems = computed(() =>
    activeHostingResources.value
      .flatMap((resource) => resource.items)
      .filter(({ state }) => state === HRESOURCE_ITEM_STATE.ACTIVE),
  );

  const pendingResources = computed(() =>
    resourcesWithSubscriptions.value.filter(filterPendingResources),
  );

  const p2pResource = computed(() =>
    activeResources.value.find(
      ({ relatedResourceId, type }) =>
        !relatedResourceId && type === HRESOURCES_TYPE.WEBSITE_BUILDER,
    ),
  );

  // Creating website object is needed because p2p users don't have hosting plan attached
  // Because of this v2/website/list-pro endpoint does not return this website.
  const p2pWebsite = computed<ProWebsiteSubscriptionType | null>(() =>
    p2pResource.value
      ? {
          pageSpeed: [],
          sslInfo: {
            id: null,
            installed: false,
          },
          website: p2pResource.value?.config?.domain || '',
          type: 'builder',
          previewLink: null,
          backup: { backupInterval: '', lastBackup: '' },
          clientId: profileStore.account?.id || '',
          resource: p2pResource.value,
          metadata: null,
          username: '',
          orderId: '',
          active: '1',
          planId: '',
          planDisplayName: '',
          vhostType: '',
          canSwitchBackUntil: '',
          action: '',
          isFreeSubdomain: null,
          createdAt: '',
          disabledReason: '',
          displayInManagedList: '0',
          softwareId: '',
          domainChange: false,
          resourceId: '',
          wordpressData: {
            directory: '',
          },
        }
      : null,
  );

  const mappedMonolithOrders = computed(() =>
    mapResourcesToMonolithOrder(resourcesWithSubscriptions.value),
  );

  const mappedHomepageOrders = computed(() => {
    const pendingOrders = [...resourcesWithSubscriptions.value]
      .filter(filterPendingResources)
      .map(mapResourceToHomepageOrder);

    const activeOrders = groupResourcesToSections(
      [...resourcesWithSubscriptions.value]
        .filter(filterActiveResource)
        .filter(filterActivatingGoogleWorkspaces)
        .map(filterActiveResourceItems),
    );

    const canceledOrders = [...resourcesWithSubscriptions.value].filter(
      filterCanceledHomepageResources,
    );

    return {
      pendingOrders,
      activeOrders,
      canceledOrders,
    };
  });

  const allActiveMonolithOrders = computed(() =>
    Object.values(mappedHomepageOrders.value.activeOrders).reduce(
      (acc, { orders }) => [...acc, ...orders],
      [] as HomepageOrder[],
    ),
  );

  const pendingPaymentResources = computed(() =>
    getResourcesByTypesAndStates({
      states: [
        HRESOURCES_STATE.ACTIVE,
        HRESOURCES_STATE.EXPIRED,
        HRESOURCES_STATE.SUSPENDED,
        HRESOURCES_STATE.DELETED,
      ],
    })
      .filter((resource) => canRenewResource(resource) && resource.subscription)
      .sort((a, b) => {
        if (a.state === HRESOURCES_STATE.DELETED) return -1;
        if (b.state === HRESOURCES_STATE.DELETED) return 1;

        return 0;
      }),
  );

  const upgradableHostingResources = computed(() => {
    const accounts = store.getters.getAllAccounts as any;

    const upgradableHostingResources = resourcesWithSubscriptions.value.filter(
      filterUpgradableHostingResources,
    );

    const nonWebsiteBuilderUpgradableHostingResources =
      upgradableHostingResources.filter((resource) =>
        filterNonWebsiteBuilderHostingResources(resource, accounts),
      );

    return nonWebsiteBuilderUpgradableHostingResources;
  });

  const activeDeveloperToolsResources = computed(() =>
    getResourcesByTypesAndStates({
      types: [HRESOURCES_TYPE.DEVELOPER_TOOLS],
      states: [HRESOURCES_STATE.ACTIVE],
    }),
  );

  const getResourcesByReferenceId = (referenceId: string) =>
    resourcesWithSubscriptions.value.find(
      (resource) => resource.referenceId === referenceId,
    );

  const getResourceById = (id: string) =>
    resourcesWithSubscriptions.value.find(
      (resource) => String(resource.id) === String(id),
    );

  const getResourceByItemDomain = (domain: string) =>
    resourcesWithSubscriptions.value.find(
      (resource) =>
        resource.items && resource.items.some((item) => item.domain === domain),
    );

  const getResourcesByIdempotencyKey = (idempotencyKey: string) =>
    resourcesWithSubscriptions.value.find(
      (resource) => resource.idempotencyKey === idempotencyKey,
    );

  const getResourceBySubscriptionId = (subscriptionId: string) =>
    resourcesWithSubscriptions.value.find(
      (resource) => resource.subscription.id === subscriptionId,
    );

  const getResourceBySubscriptionIdWithRetry = async (
    subscriptionId: string,
  ) => {
    let resource = getResourceBySubscriptionId(subscriptionId);
    if (!resource) {
      await fetchResources();

      resource = getResourceBySubscriptionId(subscriptionId);
    }

    return resource;
  };

  const getMappedMonolithOrderByType = (types: MonolithResourceType[]) =>
    mappedMonolithOrders.value.filter((order) => types.includes(order.service));

  const getMappedMonolithOrderById = (id: string) =>
    mappedMonolithOrders.value.find((order) => String(order.id) === String(id));

  const getResourcesByTypesAndStates = ({
    types,
    states,
  }: {
    types?: HResourceType[];
    states?: HResourceState[];
  }) =>
    resourcesWithSubscriptions.value.filter((resource) => {
      const hasType = types ? types.includes(resource.type) : true;
      const hasState = states ? states.includes(resource.state) : true;

      return hasType && hasState;
    });

  const getActiveResourcesByTypesAndStates = ({
    types,
    states,
  }: {
    types?: HResourceType[];
    states?: HResourceState[];
  }) =>
    activeResources.value.filter((resource) => {
      const hasType = types ? types.includes(resource.type) : true;
      const hasState = states ? states.includes(resource.state) : true;

      return hasType && hasState;
    });

  const fetchResourcesAndSubscriptions = async (
    requestConfig?: RequestConfig,
  ) => {
    await Promise.all([
      fetchResources({ requestConfig }),
      store.dispatch(
        'subscriptions/billingGetSubscriptionsWithScheduledChanges',
        requestConfig,
      ),
    ]);
  };

  return {
    resourcesWithSubscriptions,
    mappedHomepageOrders,
    pendingPaymentResources,
    activeResources,
    fetchResourcesAndSubscriptions,
    isSubscriptionsAndResourcesLoaded,
    getResourceById,
    pendingResources,
    activeHostingResources,
    upgradableHostingResources,
    p2pResource,
    p2pWebsite,
    allActiveMonolithOrders,
    activeHostingResourceItems,
    activeDeveloperToolsResources,
    getMappedMonolithOrderById,
    getMappedMonolithOrderByType,
    getResourcesByIdempotencyKey,
    getResourcesByReferenceId,
    getActiveResourcesByTypesAndStates,
    getResourceBySubscriptionId,
    getResourceBySubscriptionIdWithRetry,
    getResourcesByTypesAndStates,
    getResourceByItemDomain,
  };
};
