import type { CancelToken } from 'axios';
import cookies from 'js-cookie';

import type {
  IForwardedDomain,
  IHDomainRegistrationData,
  IHDomainsDNSSECDetails,
  IWhoIsField,
  IHDomainSuggestedAddress,
  IHDomainResource,
  IHDomainActionValidation,
  IHDomainAction,
  IHDomainExpandedDetails,
  IHDomainMoveData,
  IAiDomainAlternatives,
  IHDomainsRegistrationDomainContacts,
  IDomainLookupDetails,
  RequestConfig,
  IDomainBulkAction,
  IDnsSecRequirement,
  IHDomain,
  ResponseError,
  IDomainRenewInformation,
  IHDomainTransfer,
  IDomainAvailability,
  IDomainRegistrationRequirement,
  IHDomainsAction,
  ITldCategory,
  IDomainPurchaseData,
  TldCategoryType,
  HAsyncResponse,
  WhoIsProfile,
  NewlyRegisteredDomain,
  IHDnsZone,
  ValidateRegistrationReturnType,
} from '@/types';
import { Cookie } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';
import { snakeToCamel } from '@/utils/services/namingConventionsService';

const detailsSlugToCamel = (details: Record<string, any>) => {
  Object.entries(details).forEach(([key, detail]) => {
    details[key] = {
      ...detail,
      slug: snakeToCamel(detail.slug),
    };
  });

  return details;
};

export default {
  url: process.env.VITE_API_DOMAINS,
  async claimDomain(request: { domain: string; tld: string }) {
    return await hAsync(http.post(`${this.url}/domain/claim`, request));
  },
  async getDomain(
    domain: string,
    forceSync: boolean,
    options?: RequestConfig,
  ): HAsyncResponse<IHDomain> {
    const response = await hAsync<IHDomain>(
      http.get(
        `${this.url}/customer/domains/${domain}?force_sync=${
          forceSync ? 1 : 0
        }`,
        options,
      ),
    );
    const [{ data }, err] = response;

    if (!err) {
      Object.keys(data).forEach((key) => {
        // @ts-ignore
        if (!key.includes('Profile') || !data[key]) return;

        // @ts-ignore
        data[key].whoisDetails = detailsSlugToCamel(data[key].whoisDetails);
      });
    }

    return response;
  },
  async getDomainRenewInformation(
    domain: string,
    forceSync: boolean,
    options?: RequestConfig,
  ) {
    return await hAsync<IDomainRenewInformation>(
      http.get(
        `${this.url}/customer/domains/${domain}/renew/information?force_sync=${
          forceSync ? 1 : 0
        }`,
        options,
      ),
    );
  },
  async getUnverifiedDomains() {
    return await hAsync<IHDomainExpandedDetails>(
      http.get(`${this.url}/customer/domains/unverified`),
    );
  },
  async registerDomain(
    request: IHDomainRegistrationData,
    options?: RequestConfig,
  ) {
    // This cookie is to prevent domain from making registration request
    // for testing purposes in preview app
    type RegisterDomainResponse = [
      {
        data: NewlyRegisteredDomain | null;
      },
      { error: ResponseError | string } | null,
    ];

    if (cookies.get(Cookie.DISABLE_DOMAIN_REGISTRATION)) {
      // eslint-disable-next-line
      console.warn(
        `${Cookie.DISABLE_DOMAIN_REGISTRATION} cookies is enabled, this registration call is being mocked`,
      );

      return [{ data: {} }, null] as RegisterDomainResponse;
    }

    return await hAsync<NewlyRegisteredDomain>(
      http.post(`${this.url}/domain/register`, request, options),
    );
  },
  async transferDomain(
    request: IHDomainRegistrationData,
  ): HAsyncResponse<IHDomainTransfer> {
    // This cookie is to prevent domain from making transfer request for QA testing purposes in preview app
    if (cookies.get(Cookie.DISABLE_DOMAIN_TRANSFER)) {
      return [{ data: {} } as any, null];
    }

    return await hAsync<IHDomainTransfer>(
      http.post(`${this.url}/transfers`, request),
    );
  },
  async getDnsSecRequirements(domain: string) {
    return await hAsync<IDnsSecRequirement>(
      http.get(`${this.url}/dnssec/${domain}`),
    );
  },

  async storeDnsSecRecord(
    domain: string,
    dnsSecDetails: IHDomainsDNSSECDetails,
  ) {
    return await hAsync(
      http.patch(`${this.url}/dnssec/${domain}`, { dnsSecDetails }),
    );
  },

  async deleteDnsSecRecord(
    domain: string,
    dnsSecDetails: IHDomainsDNSSECDetails,
  ) {
    return await hAsync<void>(
      http.delete(`${this.url}/dnssec/${domain}`, {
        data: { dnsSecDetails },
      }),
    );
  },

  async storeChildDnsRecord(domain: string, nameServer: string, ip: string) {
    return await hAsync(
      http.post(`${this.url}/child-nameserver/${domain}`, {
        nameServer,
        ip,
      }),
    );
  },

  async validateNameservers(domain: string, nameServers: string[]) {
    return await hAsync<void>(
      http.post(`${this.url}/nameservers/${domain}/validate`, {
        nameServers,
      }),
    );
  },

  async deleteChildDnsRecord(domain: string, nameServer: string, ip: string) {
    return await hAsync<void>(
      http.delete(`${this.url}/child-nameserver/${domain}`, {
        data: { nameServer, ip },
      }),
    );
  },

  async updateNameservers(domain: string, nameServers: string[]) {
    return await hAsync(
      http.patch(
        `${this.url}/nameservers/${domain}`,
        { nameServers },
        {
          whitelistedStatusCodes: [404],
        },
      ),
    );
  },
  async enableDomainLock(domain: string) {
    return await hAsync(
      http.patch(`${this.url}/domain-lock/${domain}`, {
        hideToastr: true,
      }),
    );
  },
  async disableDomainLock(domain: string) {
    return await hAsync<void>(
      http.delete(`${this.url}/domain-lock/${domain}`, {
        hideToastr: true,
      }),
    );
  },
  async enablePrivacyProtection(domain: string) {
    return await hAsync(
      http.patch(`${this.url}/privacy-protection/${domain}`, null, {
        hideToastr: true,
      }),
    );
  },
  async disablePrivacyProtection(domain: string) {
    return await hAsync<void>(
      http.delete(`${this.url}/privacy-protection/${domain}`, {
        hideToastr: true,
      }),
    );
  },
  async resendVerificationEmail(domain: string) {
    return await hAsync(
      http.patch(`${this.url}/resend-verification-email/${domain}`),
    );
  },
  async getDomainActions(domain: string) {
    return await hAsync<IHDomainsAction[]>(
      http.get(`${this.url}/actions/${domain}`),
    );
  },
  async deleteDomainAction(domain: string, actionId: number) {
    return await hAsync<void>(
      http.delete(`${this.url}/actions/${domain}/${actionId}`),
    );
  },
  async getWhoisRequirements(
    params: {
      tld: string;
      entityType: string;
      countryCode: string;
    },
    options?: RequestConfig,
  ) {
    interface ResponseData {
      whoisDetails: Record<string, IWhoIsField>;
      tldDetails: Record<string, IWhoIsField> | [];
    }

    type GetWhoIsRequirementsResponse = [
      {
        data: ResponseData;
      },
      { error: ResponseError | string } | null,
    ];

    const [{ data }, err] = await hAsync<ResponseData>(
      http.get(`${this.url}/whois/requirements`, { params, ...options }),
    );

    if (!err) {
      Object.entries(data).forEach(([key, details]) => {
        // @ts-ignore
        if (!details.length === 0) return;

        // @ts-ignore
        data[key] = detailsSlugToCamel(details);
      });
    }

    return [{ data }, err] as GetWhoIsRequirementsResponse;
  }, // @ts-ignore
  async getWhoises(params) {
    const [{ data }, err] = await hAsync(
      http.get(`${this.url}/whois`, { params }),
    );

    if (!err) {
      // @ts-ignore
      data.forEach((_, index) => {
        // @ts-ignore
        if (data[index]) {
          // @ts-ignore
          data[index].whoisDetails = detailsSlugToCamel(
            // @ts-ignore
            data[index].whoisDetails,
          );
        }
      });
    }

    return [{ data }, err];
  },
  async deleteWhois(whoisId: string) {
    return await hAsync<void>(http.delete(`${this.url}/whois/${whoisId}`));
  },
  async getWhoisUsage(whoisIds: string[]) {
    return await hAsync(
      http.get(`${this.url}/whois/usage`, {
        params: { whoisIds },
      }),
    );
  },
  // @ts-ignore
  async storeWhoisProfile(request, options?: RequestConfig) {
    type StoreWhoIsProfileResponse = [
      {
        data: WhoIsProfile;
      },
      { error: ResponseError | string } | null,
    ];

    // This cookie is to prevent domain from making whois profile creation
    // for testing purposes in preview app
    if (
      cookies.get(Cookie.DISABLE_DOMAIN_REGISTRATION) ||
      cookies.get(Cookie.DISABLE_DOMAIN_TRANSFER) ||
      cookies.get(Cookie.DISABLE_DOMAIN_MOVE)
    ) {
      // eslint-disable-next-line
      console.warn(
        `Prevent whois profile creation cookie is enabled, this whois profile create call is being mocked`,
      );

      return [{ data: {} }, null] as StoreWhoIsProfileResponse;
    }

    const [{ data }, err] = await hAsync<WhoIsProfile>(
      http.post(`${this.url}/whois`, request, {
        whitelistedStatusCodes: [422],
        ...options,
      }),
    );

    // @ts-ignore
    if (!err) data.whoisDetails = detailsSlugToCamel(data.whoisDetails);

    return [{ data }, err] as StoreWhoIsProfileResponse;
  },
  // @ts-ignore
  async changeWhoisProfile(request) {
    return await hAsync(http.put(`${this.url}/whois/change`, request));
  },
  async getTldSettings(tld: string) {
    return await hAsync(http.get(`${this.url}/tld-settings/${tld}`));
  },
  async getDomainRegisterAdditionalRequirements(domain: string) {
    return await hAsync<Record<string, IDomainRegistrationRequirement>>(
      http.get(`${this.url}/domain-requirements/${domain}`),
    );
  },
  async getDomainAvailability(
    sld?: string,
    tlds?: string[],
    cancelToken?: string,
    signal?: AbortSignal,
  ) {
    return await hAsync<{}>(
      http.post(
        `${this.url}/check-availability`,
        {
          domain: sld,
          tlds,
        },
        // @ts-ignore
        { cancelToken, hideToastr: true, signal },
      ),
    );
  },
  async getDomainAvailabilityV2(
    payload: {
      domain: string;
      tlds?: string[];
      exactMatchTld?: string;
      withAlternatives?: boolean;
      isExactMatch?: boolean;
    },
    cancelToken?: CancelToken,
    signal?: AbortSignal,
  ) {
    return await hAsync<IDomainAvailability[]>(
      http.post(`${this.url}/domain/availability`, payload, {
        cancelToken,
        signal,
        hideToastr: true,
        whitelistedErrorCodes: [2000, 2025],
      }),
    );
  },
  async getSuggestedDomains(sld: string, tlds: string[]) {
    return await hAsync<Record<string, number>>(
      http.post(`${this.url}/suggest-domains`, {
        domain: sld,
        tlds,
      }),
    );
  },
  async getTransfer(domain: string, forceSync: boolean) {
    return await hAsync<IHDomainTransfer>(
      http.get(
        `${this.url}/transfers/${domain}?force_sync=${forceSync ? 1 : 0}`,
      ),
    );
  },
  async getTransferRequirements(tld: string) {
    return await hAsync<Record<string, IDomainRegistrationRequirement>>(
      http.get(`${this.url}/transfer-requirements/${tld}`),
    );
  },
  async getIsDomainTransferable(
    payload: {
      domain: string;
      tld: string;
      skipCustomerResourceCheck: boolean;
    },
    options = {},
  ) {
    return await hAsync(
      http.post(`${this.url}/transfers/validate`, payload, {
        whitelistedStatusCodes: [400, 404],
        ...options,
      }),
    );
  },
  async getResources(params?: { title?: string; resourceType?: string }) {
    return await hAsync<IHDomainResource[]>(
      http.get(`${this.url}/resources`, {
        params: { title: params?.title, resourceType: params?.resourceType },
      }),
    );
  },

  async getResource(resourceId: number) {
    return await hAsync<IHDomainResource>(
      http.get(`${this.url}/resources/${resourceId}`),
    );
  },

  async getProResources(params?: { title?: string; resourceType?: string }) {
    return await hAsync<IHDomainResource[]>(
      http.get(`${this.url}/resources/pro`, {
        params: { title: params?.title, resourceType: params?.resourceType },
      }),
    );
  },

  async lookupDomain(domain: string) {
    return await hAsync<IDomainLookupDetails>(
      http.get(`${this.url}/domain/lookup/${domain}`, {
        hideToastr: true,
        whitelistedStatusCodes: [404],
      }),
    );
  },

  async getDnsZone(domain: string) {
    return await hAsync<{ domain: string; dnsZone: IHDnsZone[] }>(
      http.get(`${this.url}/domain/lookup/${domain}/dns-zone`, {
        hideToastr: true,
        whitelistedStatusCodes: [404],
      }),
    );
  },

  // @ts-ignore
  async updateSld(resourceId, params) {
    return await hAsync(
      http.patch(`${this.url}/resources/domain/${resourceId}`, params),
    );
  },
  async validateRegistration<T extends boolean | undefined>(payload: {
    domain: string;
    tld: string;
    shouldCheckSuspicious?: T;
  }) {
    return await hAsync<ValidateRegistrationReturnType<T>>(
      http.post(`${this.url}/resources/validate-registration`, payload),
    );
  },
  async getRnCode(domain: string) {
    return await hAsync<{ authCode: string }>(
      http.get(`${this.url}/customer/domains/${domain}/auth-code`),
    );
  },
  async getPromoteYourBrandTlds(payload: { domain: string }) {
    return await hAsync<Record<string, number>>(
      http.post(`${this.url}/domain/name/promotions`, payload),
    );
  },
  async transferableTldsListIndex() {
    return await hAsync<string[]>(
      http.get(`${this.url}/transfer-preferences`, {
        cache: 36000,
      }),
    );
  },

  async fetchAddressOptions(address: string) {
    return await hAsync<{ data: IHDomainSuggestedAddress[] }>(
      http.get(`${this.url}/customer/suggest/address`, { params: { address } }),
    );
  },

  async getAllTlds() {
    return await hAsync<string[]>(http.get(`${this.url}/tlds/all`));
  },

  async createDomainForward(data: IForwardedDomain) {
    return await hAsync<IForwardedDomain>(
      http.post(`${this.url}/domain/forward`, data),
    );
  },

  async deleteForwardedDomain(domain: string) {
    return await hAsync<void>(
      http.delete(`${this.url}/domain/forward/${domain}`),
    );
  },

  async postAIDomainAlternatives(
    data: {
      domain?: string;
      limit: number;
      description?: string;
    },
    cancelToken?: CancelToken,
    signal?: AbortSignal,
  ) {
    return await hAsync<IAiDomainAlternatives>(
      http.post(`${this.url}/domain/name/alternatives`, data, {
        hideToastr: true,
        cancelToken,
        signal,
      }),
    );
  },
  async postBulkActionAvailability(
    payload: IDomainBulkAction,
    isProsumer = false,
  ) {
    return isProsumer
      ? await hAsync<IHDomainActionValidation>(
          http.post(`${this.url}/customer/prosumer/validate/actions`, payload),
        )
      : await hAsync<IHDomainActionValidation>(
          http.post(`${this.url}/customer/validate/actions`, payload),
        );
  },

  async postBulkAction(payload: IDomainBulkAction, isProsumer = false) {
    return isProsumer
      ? await hAsync<IHDomainAction[]>(
          http.post(`${this.url}/customer/prosumer/schedule/actions`, payload),
        )
      : await hAsync<IHDomainAction[]>(
          http.post(`${this.url}/customer/schedule/actions`, payload),
        );
  },

  async getBulkActions(isProsumer = false) {
    return isProsumer
      ? await hAsync<IHDomainAction[]>(
          http.get(`${this.url}/customer/prosumer/schedule/actions`, {
            hideToastr: true,
          }),
        )
      : await hAsync<IHDomainAction[]>(
          http.get(`${this.url}/customer/schedule/actions`, {
            hideToastr: true,
          }),
        );
  },

  async deleteBulkActions(isProsumer = false) {
    return isProsumer
      ? await hAsync<null>(
          http.delete(`${this.url}/customer/prosumer/schedule/actions`, {
            hideToastr: true,
          }),
        )
      : await hAsync<null>(
          http.delete(`${this.url}/customer/schedule/actions`, {
            hideToastr: true,
          }),
        );
  },

  async initiateDomainMove(
    domain: string,
    payload: {
      newCustomerEmail: string;
      subscriptionId: string;
    },
  ) {
    return await hAsync<IHDomainMoveData>(
      http.post(`${this.url}/domain-trade-actions/outgoing/${domain}`, payload),
    );
  },

  async getInitiatedDomainMove(domain: string) {
    return await hAsync<IHDomainMoveData>(
      http.get(`${this.url}/domain-trade-actions/outgoing/${domain}`),
    );
  },

  async getAllIncomingDomainMoves() {
    return await hAsync<IHDomainMoveData[]>(
      http.get(`${this.url}/domain-trade-actions/incoming`),
    );
  },

  async getIncomingDomainMoveStatus(domain: string, forceSync = 0) {
    return await hAsync<IHDomainMoveData>(
      http.get(`${this.url}/domain-trade-actions/incoming/${domain}`, {
        params: { forceSync },
      }),
    );
  },

  async cancelDomainMoveOutgoing(domain: string) {
    return await hAsync<void>(
      http.delete(`${this.url}/domain-trade-actions/outgoing/${domain}`),
    );
  },

  async cancelDomainMoveIncoming(domain: string) {
    return await hAsync<void>(
      http.delete(`${this.url}/domain-trade-actions/incoming/${domain}`),
    );
  },

  async acceptDomainMoveIncoming(
    domain: string,
    domainContacts: IHDomainsRegistrationDomainContacts,
  ) {
    // This cookie is to prevent domain from making move domain request
    // for testing purposes in preview app
    if (cookies.get(Cookie.DISABLE_DOMAIN_MOVE)) {
      // eslint-disable-next-line
      console.warn(
        `${Cookie.DISABLE_DOMAIN_MOVE} cookies is enabled, this domain move call is being mocked`,
      );

      return [{ data: {} }, null];
    }

    return await hAsync(
      http.patch(`${this.url}/domain-trade-actions/incoming/${domain}`, {
        domainContacts,
      }),
    );
  },

  async getDomainDescription(payload: {
    domain: string;
    tld: string;
    language: string;
  }) {
    return await hAsync<{ description: string }>(
      http.post(`${this.url}/domain/name/description`, payload),
    );
  },

  async getTldCategories() {
    const CACHE_30_MINUTES = 1800;

    return await hAsync<ITldCategory[]>(
      http.get(`${this.url}/domain/availability/categories`, {
        cache: CACHE_30_MINUTES,
      }),
    );
  },

  async getDomainPurchaseData(
    payload: {
      domain: string;
      tlds: string[];
      tag?: TldCategoryType;
      withAlternatives?: boolean;
      withPromotions?: boolean;
    },
    cancelToken?: CancelToken,
  ) {
    return (await hAsync<IDomainPurchaseData[]>(
      http.post(`${this.url}/domain-availability`, payload, {
        cancelToken,
      }),
    )) as [
      { data: IDomainPurchaseData[] },
      { error: ResponseError | string } | null,
    ];
  },

  async getDomainPromotionsBundle(
    data: { domain: string; tld: string },
    config: RequestConfig = {},
  ) {
    return await hAsync<string[]>(
      http.post(`${this.url}/domain/promotions/bundle`, data, config),
    );
  },
};
