import { computed, ref } from 'vue';

import LtDomainTransferInstructionsModal from '@/components/Modals/HModal/HDomains/LtDomainTransferInstructionsModal.vue';
import RegistroBrTransferTermsModal from '@/components/Modals/HModal/HDomains/RegistroBrTransferTermsModal.vue';
import UkDomainTransferTermsModal from '@/components/Modals/HModal/HDomains/UkDomainTransferTermsModal.vue';
import { useModal } from '@/composables';
import { hDomainsRepo } from '@/repositories';
import { useDnsManagementStore } from '@/stores';
import type { IHDnsZone } from '@/types';
import { HDomains } from '@/types';
import {
  removeLeadingDot,
  getSplittedDomainParts,
  toASCII,
} from '@/utils/helpers';
import { isRegistroBrTransfer } from '@/utils/helpers/domainsHelper';
import {
  isLtTld,
  isUkTld,
} from '@/utils/helpers/hDomains/domainRegistrationHelper';

const selectedKeepDnsOption = ref(HDomains.DomainTransferDnsOption.RESET_DNS);

export const useTransferDomainSetup = (domain: string) => {
  const { openModal } = useModal();
  const { getDomainDnsRecords, getFormattedDnsZonePayload } =
    useDnsManagementStore();
  const [, tld] = getSplittedDomainParts(domain, { omitDot: true });

  const hasSpecialTransferTerms = computed(
    () => isRegistroBrTransfer(tld) || isLtTld(tld) || isUkTld(tld),
  );

  const openDomainTransferTermsModal = () => {
    if (isLtTld(tld)) {
      openModal({ component: { LtDomainTransferInstructionsModal } });

      return;
    }

    if (isRegistroBrTransfer(tld)) {
      openModal({ component: { RegistroBrTransferTermsModal } });
    }

    if (isUkTld(tld)) {
      openModal({ component: { UkDomainTransferTermsModal } });
    }
  };

  const transferRegistrationRequest = computed(() => {
    const payload: {
      shouldKeepDns: boolean;
      shouldKeepNs: boolean;
      dnsZone?: IHDnsZone[];
    } = {
      shouldKeepDns: isKeepDnsOptionSelected.value,
      shouldKeepNs:
        selectedKeepDnsOption.value ===
        HDomains.DomainTransferDnsOption.KEEP_NS,
    };

    const domainDnsRecords = getDomainDnsRecords(toASCII(domain));

    if (isKeepDnsOptionSelected.value && domainDnsRecords) {
      payload.dnsZone = getFormattedDnsZonePayload(domainDnsRecords);
    }

    return payload;
  });

  const isKeepDnsOptionSelected = computed(
    () =>
      selectedKeepDnsOption.value === HDomains.DomainTransferDnsOption.KEEP_DNS,
  );

  const fetchTransferRequirements = async () =>
    await hDomainsRepo.getTransferRequirements(removeLeadingDot(tld));

  const resetSelectedKeepDnsOption = () => {
    selectedKeepDnsOption.value = HDomains.DomainTransferDnsOption.RESET_DNS;
  };

  return {
    hasSpecialTransferTerms,
    openDomainTransferTermsModal,
    selectedKeepDnsOption,
    transferRegistrationRequest,
    fetchTransferRequirements,
    isKeepDnsOptionSelected,
    resetSelectedKeepDnsOption,
  };
};
